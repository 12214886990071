import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, Event, NavigationStart, NavigationCancel, NavigationEnd, NavigationError } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent{

  constructor(private spinner: NgxSpinnerService, private route: Router){
    this.route.events.subscribe((event: Event)=>{
      switch(true){
        case event instanceof NavigationStart: {
          this.spinner.show();
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError:{
          this.spinner.hide();
          break;
        }
        default: {
          break;
        }
      }
    });
  }


}
