import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from './components/error/error.component';

const routes: Routes = [
  {path: '', redirectTo:'login', pathMatch: 'full'},
  {path: '', loadChildren: () => import('./components/account/account.module').then(m=> m.AccountModule)},
  {path: '', loadChildren: () => import('./components/dashboard/dashboard.module').then(m=> m.DashboardModule)},
  {path: 'school', loadChildren: () => import('./components/school/school.module').then(m=> m.SchoolModule)},
  {path: 'marketing', loadChildren: () => import('./components/marketing/marketing.module').then(m=> m.MarketingModule)},
  {path: 'attendance', loadChildren: () => import('./components/attendance/attendance.modules').then(m=> m.AttendanceModule)},
  {path: 'day-plan-summary', loadChildren: () => import('./components/day-plan-summary/day-plan-summary.modules').then(m=> m.DayPlanSummaryModule)},
  {path: 'task-planner', loadChildren: () => import('./components/task-planner/task-planner.modules').then(m=> m.TaskPlannerModule)},
  {path: 'application-setting', loadChildren: () => import('./components/application-setting/application-setting.modules').then(m=> m.ApplicationSettingModule)},
  // {path: 'securityRole', loadChildren: () => import('./components/admin-management/admin-management.module').then(m=> m.AdminManagementModule)},
  {path: '**', component: ErrorComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
